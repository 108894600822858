.middleContainer {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  margin-top: 165px;
  line-height: 150%;
  color: white;
}

.subtitle {
  font-size: 22px;
}
.explore {
  width: 149px;
  height: 52px;
  background: #ffffff;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  border-radius: 100px;
  margin: auto;
  padding: 10px;
}

.explore > h1 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  color: #000000;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 480px) {
  .middleContainer {
    font-size: 15px;
    margin-top: 165px;
  }
  .middleContainer h1 {
    line-height: 35px;
    margin: 0;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 20px;
  }
}
