.login-container{
    display: flex;
    height:100vh;
    align-items: center;
    justify-content:center;
    flex-direction: row;
   
   
}


.login-container .login-content {
    flex:1;
    background-color: gray;
    height:100vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.login-container .login-input{
    flex:1;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
    background-color: #7FB1B4;
    height:100vh;
}
.login-input h1{
    color:white;
    font-size: 40px;
}

.login-input .flash p {
color:red;
font-size: 18px;
}
.login-input .input-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:50%;
    min-width:20%
}
.login-input div{
display: flex;
flex-direction: column;
margin: 10px;
}
.login-input label{
    font-size: 18px;
}
.login-input input{
    border:none;
    height: 30px;
    padding-left: 5px;
    margin-top: 5px;
}
.login-input  button{
    height: 40px;
    width:60%;
    border:none;
    background-color:green;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
    align-self: center;
    cursor: pointer;

}