.custom {
  display: flex;
  height: 100vh;
  background-color: #edf3fc;
}
.subCustom1 {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subCustom2 {
  padding-top: 200px;
  width: 50vw;
  padding-left: 80px;
}
.custom_bump {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  color: #434343;
}
.custom_img {
  width: 50%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .custom {
    height: 100%;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
  .custom_bump {
    font-size: 40px;
  }
  .subCustom2 {
    padding: 20px 30px;
    width: 80vw;
  }
  .subCustom2_desc {
    width: 100%;
  }
  .custom_img {
    width: 84%;
  }
  .subCustom1 {
    width: 85vw;
    padding: 100px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
