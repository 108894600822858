.photo_wrpr {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo_wrpr .prof_container {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.25);
}

.photo_wrpr .prof_pic {
  width: 25px;
  height: 25px;
  border-radius: 100px;
}

.photo_wrpr .on_of_label{
  font-weight: 300;
  font-size: 12px;
}
.name_wrpr {
  padding: 30px;
}
.name_wrpr p {
  color: black;
  margin: 10px 0;
  padding: 0;
}
.name_wrpr .input {
  border: 1px solid gray;
  padding: 5px;
}

.name_wrpr .link {
  margin-top: 25px;
  color: grey;
}

.country_code{
  margin-top: 10px;
width: 100px;
height: 40px;
}