.footerMain {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}
.footer-sub {
  /* padding-left: 128px; */
  width: 40%;
  justify-content: space-around;
  /* background-color: red; */
}

@media (min-width: 320px) and (max-width: 480px) {
  .footerMain {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .footer-sub {
    padding-left: 0;
    padding-right: 50px;
    width: 70%;
    justify-content: center;
  }
}
