.product-main {
  height: 100vh;
}
.pdt-item {
  width: 269px;
  height: 269px;
  border-radius: 20px;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdt-div1 {
  width: 103px;
  height: 103px;
  background-color: #c4c4c4;
  border-radius: 60px;
  align-self: center;
}
.main {
  height: 100vh;
  width: 100%;
}
.pdt-img {
  width: 140px;
  height: 100px;
  object-fit: contain;
}
.pdt-title {
  margin-top: 70px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.swiper {
  max-width: 100vw;
}

@media (min-width: 320px) and (max-width: 480px) {
  .product-main {
    height: 100%;
    padding-bottom: 30px;
  }
  .pdt-item {
    height: 400px;
  }
  .pdt-img {
    width: 180px;
    object-fit: contain;
  }
  .main {
    height: 110vh;
  }
  .swiper {
    margin-top: -70px;
  }
  .button_b {
    margin-top: -150px;
  }
  .main {
    height: 95vh;
  }
}
