.setup_profile .subTitle {
    margin: 20px;
    font-size: 20px;
    color: gray;
    justify-self: flex-start;
    align-self: flex-start;
  }
  .setup_profile .socialContainer {
    margin: 0 auto 20px auto;
    display: flex;
    flex-wrap: wrap;
  }