body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-container{
    display: flex;
    height:100vh;
    align-items: center;
    justify-content:center;
    flex-direction: row;
   
   
}


.login-container .login-content {
    flex:1 1;
    background-color: gray;
    height:100vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.login-container .login-input{
    flex:1 1;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
    background-color: #7FB1B4;
    height:100vh;
}
.login-input h1{
    color:white;
    font-size: 40px;
}

.login-input .flash p {
color:red;
font-size: 18px;
}
.login-input .input-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:50%;
    min-width:20%
}
.login-input div{
display: flex;
flex-direction: column;
margin: 10px;
}
.login-input label{
    font-size: 18px;
}
.login-input input{
    border:none;
    height: 30px;
    padding-left: 5px;
    margin-top: 5px;
}
.login-input  button{
    height: 40px;
    width:60%;
    border:none;
    background-color:green;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
    align-self: center;
    cursor: pointer;

}
.dB-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.signout{
    align-self: flex-end;
}
.input-wrpr {
  margin: 20px;
  width: 80%;
  display: flex;
  justify-content: center;
}
.input-wrpr input {
  margin-right: 20px;
  padding: 5px;
}
.dB-table {
  width: 80%;
  display: flex;
  justify-content: center;
}
.dB-container table {
  border: 1px solid black;
  max-width: 80%;
  min-width: 60%;
}
.dB-container th {
  border-bottom: 2px solid black;
}
.dB-container td{
text-align: center;    
padding-top: 10px;
}

.login-container{
    display: flex;
    height:100vh;
    align-items: center;
    justify-content:center;
    flex-direction: row;
   
   
}


.login-container .login-content {
    flex:1 1;
    background-color: gray;
    height:100vh;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.login-container .login-input{
    flex:1 1;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
    background-color: #7FB1B4;
    height:100vh;
}
.login-input h1{
    color:white;
    font-size: 40px;
}

.login-input .flash p {
color:red;
font-size: 18px;
}
.login-input .input-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:50%;
    min-width:20%
}
.login-input div{
display: flex;
flex-direction: column;
margin: 10px;
}
.login-input label{
    font-size: 18px;
}
.login-input input{
    border:none;
    height: 30px;
    padding-left: 5px;
    margin-top: 5px;
}
.login-input  button{
    height: 40px;
    width:60%;
    border:none;
    background-color:green;
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
    align-self: center;
    cursor: pointer;

}
.contactBtn {
  width: 100px;
  height: 100px;
  margin: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contactBtn img {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.account_socio_btn {
  height: 130px;

  /* border: 0.5px solid green; */
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactBtn {
    margin: 10px 0;
    padding: 0;
    width: 70px;
    height: 70px;
  }
  .contactBtn img {
    margin: 10px 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .contactBtn p {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: white;
    font-family: Poppins;
  }
}

.userProfile {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 10px;
  background-color: black;
}
.userProfile .sub_1 {
  width: 100vw;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.userProfile .logo {
  height: 150px;
  margin-bottom: 20px;
  /* z-index: 10; */
}

.userProfile .publicUsername {
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  max-width: 1000px;
}
.userProfile .designation {
  padding: 0 0 10px 0;
  margin: 0;
  text-align: left;
  color: white;
  font-family: Poppins;
}
/* .userProfile .profilePic {
  width: 120px;
  height: 120px;

  border-radius: 100px;
  z-index: 10;
} */

.userProfile .socialContainer {
  padding: 10px 0;

  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
}
.userProfile .save {
  margin-top: 20px;
  height: 50px;
  width: 335px;
  border: none;
  background-color: #4784e1;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  z-index: 10;
}
.mob_userProfile {
  display: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .userProfile {
    padding-top: 0px;

    display: none;
  }
  .mob_userProfile {
    display: flex;
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }
  .sub_1 {
    width: 100vw;
  }
  .mob_userProfile .wrpr {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    scroll-snap-align: start;
  }

  .mob_userProfile .usernameWrpr {
    margin-top: 20px;
    height: 100vh;
    color: white;
    font-family: Poppins;
    width: 100vw;
    text-align: center;
  }
  .mob_userProfile .designation {
    font-weight: bold;
    font-family: Poppins;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .mob_userProfile .arrow {
    height: 20px;
    width: 20px;
    color: white;
  }
  .mob_userProfile .arrow1 {
    height: 20px;
    width: 20px;
    margin-left: -30px;
    color: white;
    margin-bottom: -30px;
  }
  .mob_userProfile .arrow2 {
    height: 20px;
    width: 20px;
    color: white;
    margin-left: 300px;
    margin-bottom: -30px;
  }
  .mob_userProfile .scroll {
    height: 10px;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    fill: white;
  }

  .mob_userProfile .publicUsername {
    font-size: 35px;
    font-weight: bold;
    padding: 0;
    text-align: center;
    margin: 0;
    font-family: Poppins;
  }
  .mob_userProfile .sub_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: linear-gradient(
      rgba(71, 132, 225, 0),
      rgba(71, 132, 225, 0.9)
    ); */
    /* height: 100%; */
    height: 100vh;
    align-items: center;
  }
  .mob_userProfile .socialContainer {
    /* margin-top: 20px; */
    width: 100vw;
    /* padding: 0px 0; */

    display: flex;
    flex-direction: row;
    padding-top: 60px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mob_userProfile .logo {
    height: 100px;
    padding-top: 10px;
    justify-self: flex-end;
  }
}

.scrollbar {
  height: 100vh;
}

.scrollbarr {
  scroll-snap-align: start;
}

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .sub_1 {
    background-attachment: initial;
  }
} */

.footerMain {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}
.footer-sub {
  /* padding-left: 128px; */
  width: 40%;
  justify-content: space-around;
  /* background-color: red; */
}

@media (min-width: 320px) and (max-width: 480px) {
  .footerMain {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .footer-sub {
    padding-left: 0;
    padding-right: 50px;
    width: 70%;
    justify-content: center;
  }
}

.contactMain {
  height: 80vh;
  padding-bottom: 25px;
}

.contactOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.us {
  padding-left: 180px;
  padding-top: 100px;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
}
.contactSubtxt {
  width: 500px;
  font-size: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  line-height: 180%;
}
.contact {
  padding-left: 100px;
}
.contact_btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 0px 30px;
  color: white;
  border-radius: 40px;
  cursor: pointer;
}
.contact_btn_text {
  font-weight: 500;
  font-size: 25px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .contactMain {
    padding-bottom: 0;
  }
  .contactOuter {
    flex-direction: column;
  }

  .contactSubtxt {
    width: 300px;
  }
  .us {
    padding-left: 20px;
    padding-top: 20px;
  }
  .contact {
    padding: 5px;
    padding-top: 20px;
  }
}

.custom {
  display: flex;
  height: 100vh;
  background-color: #edf3fc;
}
.subCustom1 {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subCustom2 {
  padding-top: 200px;
  width: 50vw;
  padding-left: 80px;
}
.custom_bump {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  color: #434343;
}
.custom_img {
  width: 50%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .custom {
    height: 100%;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
  .custom_bump {
    font-size: 40px;
  }
  .subCustom2 {
    padding: 20px 30px;
    width: 80vw;
  }
  .subCustom2_desc {
    width: 100%;
  }
  .custom_img {
    width: 84%;
  }
  .subCustom1 {
    width: 85vw;
    padding: 100px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 0 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  /* background-color: blue; */
}

.header_logo {
  padding-right: 20px;
  padding-left: 70px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  color: "#FFFFFF";
}
.header_logo > h4 {
  color: white;
}
.header_right {
  display: flex;
  flex-direction: row;
}

.header_right > p {
  padding-right: 30px;
  padding-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: white;
}
.header_right div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 52px;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 100px;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
}
@media (min-width: 320px) and (max-width: 480px) {
  .header {
    justify-content: space-around;
  }
  .header_logo {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header_right p {
    padding-right: 20px;
    padding-left: 5px;
    font-size: 13px;
  }
  .header_right div {
    align-self: center;
    width: 100px;
    height: 26px;
    background-color: #ffffff;
    border-radius: 100px;
    box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  }
  .header_right div p {
    font-size: 12px;
    padding-right: 0;
  }
}

.middleContainer {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  margin-top: 165px;
  line-height: 150%;
  color: white;
}

.subtitle {
  font-size: 22px;
}
.explore {
  width: 149px;
  height: 52px;
  background: #ffffff;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  border-radius: 100px;
  margin: auto;
  padding: 10px;
}

.explore > h1 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  color: #000000;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 480px) {
  .middleContainer {
    font-size: 15px;
    margin-top: 165px;
  }
  .middleContainer h1 {
    line-height: 35px;
    margin: 0;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 20px;
  }
}

.itemcontainer {
  overflow: auto;
  height: 100vh;
  background-image: linear-gradient(#163d77, rgba(61, 58, 219, 0.6)),
    url(/static/media/phone.99540193.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.intro,
.connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  padding: 10;
}

.intro-sub1 {
  display: flex;
  flex: 0.6 1;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.intro-sub1 div {
  width: 80%;
}
.ntro-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.1em;
}

.intro-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  color: #434343;
}
.intro-t3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
}
.intro-images {
  display: flex;
  flex: 0.4 1;
  padding: 50px 20px;
}
.intro-img1 {
  margin-left: 100px;
  position: absolute;
}
.intro-img2 {
  margin-left: 220px;
  margin-top: -40px;
}
.connect {
  display: flex;
  height: 100vh;
  padding: 30px;
}
.connect-images {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.connect-info {
  flex: 1 1;
  margin-left: 20px;
}
.connect-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  color: #434343;
}
.connect-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 170%;
  color: #575757;
  margin-left: 20px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .intro {
    flex-direction: column;
    height: 100%;
  }

  .intro-sub1 {
    justify-content: flex-start;
  }
  .intro-t3 {
    font-size: 15px;
  }
  .intro-images {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .intro-img1,
  .intro-img2 {
    width: 50%;
    margin-left: 40px;
  }
  .intro-img2 {
    margin-left: 120px;
  }
  .images {
    margin-top: 100px;
  }
  .connect {
    flex-direction: column-reverse;
    height: 100%;
  }
  .connect-t1 {
    font-size: 25px;
    line-height: 130%;
  }
  .connect-t2 {
    font-size: 12px;
  }
  .connect-img {
    margin-top: 50px;
    width: 80%;
  }
}

.product-main {
  height: 100vh;
}
.pdt-item {
  width: 269px;
  height: 269px;
  border-radius: 20px;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdt-div1 {
  width: 103px;
  height: 103px;
  background-color: #c4c4c4;
  border-radius: 60px;
  align-self: center;
}
.main {
  height: 100vh;
  width: 100%;
}
.pdt-img {
  width: 140px;
  height: 100px;
  object-fit: contain;
}
.pdt-title {
  margin-top: 70px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.swiper {
  max-width: 100vw;
}

@media (min-width: 320px) and (max-width: 480px) {
  .product-main {
    height: 100%;
    padding-bottom: 30px;
  }
  .pdt-item {
    height: 400px;
  }
  .pdt-img {
    width: 180px;
    object-fit: contain;
  }
  .main {
    height: 110vh;
  }
  .swiper {
    margin-top: -70px;
  }
  .button_b {
    margin-top: -150px;
  }
  .main {
    height: 95vh;
  }
}

.products_screen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.products {
  display: flex;
  padding-top: 40px;
  justify-content: space-around;
}
.modal {
  width: 400px;
  height: 400px;
}

.custom_products {
  display: flex;
  padding-top: 40px;

  padding-bottom: 20px;
  justify-content: space-around;
}

.sticker {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  /* filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08)); */
  border-radius: 30px;
}

.card {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  /* filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08)); */
  border-radius: 30px;
}

.rectangle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.img {
  width: 224px;
  height: 145px;
}

.details {
  font-family: Poppins;
  line-height: 18px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.details_sticker {
  font-family: Poppins;
  padding-top: 10px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.details1 {
  font-family: Poppins;
  line-height: 18px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.cart {
  width: 130px;
  height: 40px;
  background-color: black;
  margin-top: 5px;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  margin-left: 30vw;
}
.cart div {
  background-color: white;
  width: 25px;
  height: 25px;
  margin-top: -60px;
  margin-left: 100px;
  border-radius: 70px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.row2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.combo_img {
  width: 220px;
  height: 120px;
}

.products_title {
  text-align: "center";
  font-family: Poppins;
  font-style: normal;
  font-size: 50px;
  line-height: 75px;
  margin-left: 40vw;
}
.combo_imagediv {
  /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08); */
  border-radius: 8px;
  margin-left: 100px;
}

.customImage {
  /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08); */
  border-radius: 8px;
  width: 224px;
  margin-left: 30px;
}

.combo_details {
  margin-left: 60px;
}
.inner_modal {
  margin-left: 60px;
}
.buttons {
  margin-top: 10px;
  cursor: pointer;
  background-color: #4784e1;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  padding: 5px;
}

.buttons2 {
  margin-top: 10px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  padding: 5px;
  border: 1px solid black;
  margin-left: 10px;
}

.button-text {
  margin: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: white;
}

.button-text2 {
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: black;
}

@media (min-width: 320px) and (max-width: 480px) {
  .products {
    display: flex;
    padding-top: 10px;

    flex-direction: column;
  }
  .buttons {
    width: 60px;
  }

  .button-text {
    padding-left: 8px;
  }

  .buttons2 {
    width: 60px;
    margin-left: 2px;
  }

  .button-text2 {
    padding-left: 8px;
  }

  .inner_modal {
    margin-left: -10px;
  }
  .modal {
    width: 250px;
    height: 400px;
  }
  .custom_products {
    display: flex;
    padding-top: 10px;
    padding-left: 4px;
    flex-direction: column;
  }
  .card {
    width: 90vw;
    margin: 20px;
    height: 300px;
  }
  .img {
    width: 150px;
    height: 100px;
  }
  .cart {
    margin-left: 5vw;
  }
  .cart div {
    width: 20px;
    height: 20px;
    margin-left: 80px;
  }
  .cart p {
    font-size: 10px;
  }
  .sticker {
    width: 90vw;
    margin: 20px;
    height: 300px;
  }
  .sticker_1 {
    margin-left: 20px;
  }
  .details {
    width: 140px;
  }
  .details1 {
    width: 140px;
  }
  .row2 {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
  }
  .combo_img {
    width: 130px;
    height: 100px;
  }
  .combo_details {
    padding-left: -40px;
  }
  .combo_imagediv {
    margin-left: 110px;
  }
  .customImage {
    width: 150px;
    height: 100px;
  }
  .products_title {
    margin-left: 5vw;
  }
}

.sticker1 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  -webkit-filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08));
          filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08));
  border-radius: 30px;
}

.sticker_image {
  width: 150px;
}

.img1 {
  width: 224px;
  height: 145px;
}

.img2 {
  width: 224px;
  height: 145px;
}
.img3 {
  width: 224px;
  height: 145px;
}

.card1 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.card2 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.card3 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.sticker_class {
  height: 150px;
  width: 150px;
  border-radius: 90px;
}

.customImage1 {
  border-radius: 8px;
  width: 224px;
  margin-left: 30px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sticker1 {
    width: 70vw;
    height: 260px;
  }
  .sticker_image {
    width: 100px;
  }
  .sticker_class {
    height: 100px;
    width: 100px;
  }
  .card1 {
    width: 70vw;
    height: 260px;
  }
  .card2 {
    width: 70vw;
    height: 260px;
  }
  .card3 {
    width: 70vw;
    height: 260px;
  }
  .img1 {
    width: 100px;
    height: 50px;
  }
  .img2 {
    width: 100px;
    height: 50px;
  }
  .combo_left {
    padding-left: 30px;
  }
  .customImage1 {
    width: 100px;
    height: 60px;
  }
  .img3 {
    width: 100px;
    height: 50px;
  }
}

.whyMain {
  background-color: #edf3fc;
  padding-top: 50px;

}
.whySub {
  margin-left: 210px;
}
.why-h1 {
  margin-top: 70px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 34px;
  line-height: 66px;
  color: #434343;
}
.why-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.why-1 {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.why-txtwrpr {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 400px;
  height: 300px;
}
.img-why {
  background-color: white;
  border-radius: 24px;
  width: 35px;
  height: 38px;
  text-align: center;
  box-shadow: 4px 4px 10px rgba(118, 117, 117, 0.25);
}
.why-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;

}
.why-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.why-t3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 400px;
  padding: 0;
  margin: 0;
}
@media (min-width: 320px) and (max-width: 480px) {
  .whyMain{
    padding: 10px;
    padding-top: 10px;

  }
  .whySub {
    margin-left: 20px;
  }
  .why-1{
    width: 90%
  }
  .why-txtwrpr{
    width: 100%;
    height:auto;
  }
  .why-t3,.why-t1,.why-t2 {
    width: 100%;
    font-size: 12px;
  }
}

.sect_1 {
  background-color: #4287f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.faq .wrpr {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  color: white;
}
.faq .wrpr h1 {
  margin: 0 10px;
  font-weight: 600;
  font-size: 58px;
  text-align: center;
}
.faq .wrpr p {
  font-size: 22px;
}
.faq .sect_2 {
  padding: 120px 30px 18px 110px;
  display: flex;
  flex-direction: column;
  background-color: rgba(71, 132, 225, 0.1);
}
.faq .sect_2 {
  font-size: 22px;
  line-height: 33px;
}
.faq .sect_2 h1 {
  font-size: 44px;
  align-self: center;
  color: #434343;
}

@media only screen and (max-width: 600px) {
  .faq .wrpr h1 {
    margin-top: 40px;
    font-size: 40px;
  }
  .faq .wrpr p {
    font-size: 18px;
  }
  .faq .sect_2 {
    padding: 80px 30px 18px 30px;
    font-size: 18px;
  }
  .faq .sect_2 .info {
    text-align: center;
    margin-top: 40px;
  }
  
}

.activate {
  height: 100vh;
  display: flex;
}
.activate .text_wrpr {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.1em;
  padding: 20px;
  color: #434343;
}
.activate .img_wrpr {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activate .img {
  height: 60%;
  width: 60%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .activate {
    flex-wrap: wrap;
  }
  .activate .text_wrpr {
    width: 100%;
  }
  .activate .img_wrpr {
    width: 100%;
  }
  .activate .img {
    height: 80%;
    width: 80%;
  }
}

.how_to {
  margin-top: 80px;
}
.item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  flex-direction: row-reverse;
}
.how_to > div:nth-of-type(odd) {
  background-color: #edf3fc;
}

.img_wrpr {
  width: 50%;
  /* padding-left: 25px; */
  /* background-color: yellow; */
}
.how_to .img {
  height: auto;
  object-fit: contain;
}
.how_to .text_wrpr {
  height: 100%;
  width: 35%;
  padding: 25px;
  /* background-color: yellow; */
}
.how_to .txt {
  margin-top: 20px;
  font-size: 18px;
  text-align: start;
}
@media (min-width: 320px) and (max-width: 480px) {
  .how_to {
    margin-top: 0px;
  }
  .item {
    height: 90vh;
  }
  .sec {
    flex-direction: column;
  }
 .how_to .img_wrpr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how_to .img {
    object-fit: contain;
  }
  .how_to .text_wrpr {
    width: 90%;
  }
}

.profile-off {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.auth_header {
  width: 100%;
  height: 150px;
  /* position: absolute;
  top: 0%;
  left: 0%; */
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  border-radius: 0 0 100% 100%;
  background-color: #558ee30d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_header p {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.login {
  height: 90vh;
  display: flex;
  flex-direction: column;

  align-items: center; /* background-color: rgb(170, 170, 228); */
}

.login .sub {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 40%;
}
.auth_input_wrpr {
  margin-top: 50px;
  width: 100%;
  border-bottom: 1px solid #383838a6;
  padding-bottom: 5px;
}
.auth_input {
  border: none;
  width: 100%;
}
.forgot {
  margin: 10px 0;
  align-self: start;
  color: #383838;
  font-size: 12px;
}

.login .cbx_wrpr {
  display: flex;
  justify-content: start;
  font-size: 12;
}

.otp_page .sub {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.otp_page .info {
  font-size: 15px;
  text-align: center;
  width: 80%;
}
.resend {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #383838;
  font-size: 13px;
  align-self: flex-start;
}
@media (min-width: 320px) and (max-width: 480px) {
  .auth_input_wrpr {
    width: 100%;
  }
  .login .sub {
    width: 95%;
  }
}

.account * {
  padding: 0;
  margin: 0;
}
input:focus {
  /* border: none; */
  outline: none;
}
.account {
}
.account .sub {
  margin: auto;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account .subTitle {
  margin: 20px;
  font-size: 20px;
  color: gray;
  justify-self: flex-start;
  align-self: flex-start;
}
.account .socialContainer {
  margin: 0 auto 20px auto;
  display: flex;
  flex-wrap: wrap;
}



.create_username {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create_username .sub {
  width: max(40%,300px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.create_username .info {
  font-size: 15px;
  text-align: center;
  width: 80%;
}
.create_username .link_wrpr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create_username .dot {
  background-color: #c5fdaa;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create_username .dot p {
  margin: 0;
  padding: 0;
  color: white;
}

.setup_profile .subTitle {
    margin: 20px;
    font-size: 20px;
    color: gray;
    justify-self: flex-start;
    align-self: flex-start;
  }
  .setup_profile .socialContainer {
    margin: 0 auto 20px auto;
    display: flex;
    flex-wrap: wrap;
  }
.photo_wrpr {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo_wrpr .prof_container {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(158, 158, 158, 0.25);
}

.photo_wrpr .prof_pic {
  width: 25px;
  height: 25px;
  border-radius: 100px;
}

.photo_wrpr .on_of_label{
  font-weight: 300;
  font-size: 12px;
}
.name_wrpr {
  padding: 30px;
}
.name_wrpr p {
  color: black;
  margin: 10px 0;
  padding: 0;
}
.name_wrpr .input {
  border: 1px solid gray;
  padding: 5px;
}

.name_wrpr .link {
  margin-top: 25px;
  color: grey;
}

.country_code{
  margin-top: 10px;
width: 100px;
height: 40px;
}
.acc_settings {
    height: 90vh;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc_settings .sub {
  width: 30%;
  flex: 1 1;
}

.acc_settings .logout {
    cursor: pointer;
    margin-top: 250px;
}
@media only screen and (max-width: 600px) {
  .acc_settings .sub {
    width: 100%;
  }
}

.delete_account {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
}
.delete_account .sub {
  padding: 20px;
  width: 30%;
}
.delete_account .input {
  width: 90%;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .delete_account .sub {
    width: 90%;
    /* background-color: red; */
  }
}

