.intro,
.connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  padding: 10;
}

.intro-sub1 {
  display: flex;
  flex: 0.6;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.intro-sub1 div {
  width: 80%;
}
.ntro-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.1em;
}

.intro-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  color: #434343;
}
.intro-t3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
}
.intro-images {
  display: flex;
  flex: 0.4;
  padding: 50px 20px;
}
.intro-img1 {
  margin-left: 100px;
  position: absolute;
}
.intro-img2 {
  margin-left: 220px;
  margin-top: -40px;
}
.connect {
  display: flex;
  height: 100vh;
  padding: 30px;
}
.connect-images {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.connect-info {
  flex: 1;
  margin-left: 20px;
}
.connect-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  color: #434343;
}
.connect-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 170%;
  color: #575757;
  margin-left: 20px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .intro {
    flex-direction: column;
    height: 100%;
  }

  .intro-sub1 {
    justify-content: flex-start;
  }
  .intro-t3 {
    font-size: 15px;
  }
  .intro-images {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .intro-img1,
  .intro-img2 {
    width: 50%;
    margin-left: 40px;
  }
  .intro-img2 {
    margin-left: 120px;
  }
  .images {
    margin-top: 100px;
  }
  .connect {
    flex-direction: column-reverse;
    height: 100%;
  }
  .connect-t1 {
    font-size: 25px;
    line-height: 130%;
  }
  .connect-t2 {
    font-size: 12px;
  }
  .connect-img {
    margin-top: 50px;
    width: 80%;
  }
}
