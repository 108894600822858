.userProfile {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 10px;
  background-color: black;
}
.userProfile .sub_1 {
  width: 100vw;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.userProfile .logo {
  height: 150px;
  margin-bottom: 20px;
  /* z-index: 10; */
}

.userProfile .publicUsername {
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  max-width: 1000px;
}
.userProfile .designation {
  padding: 0 0 10px 0;
  margin: 0;
  text-align: left;
  color: white;
  font-family: Poppins;
}
/* .userProfile .profilePic {
  width: 120px;
  height: 120px;

  border-radius: 100px;
  z-index: 10;
} */

.userProfile .socialContainer {
  padding: 10px 0;

  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
}
.userProfile .save {
  margin-top: 20px;
  height: 50px;
  width: 335px;
  border: none;
  background-color: #4784e1;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  z-index: 10;
}
.mob_userProfile {
  display: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .userProfile {
    padding-top: 0px;

    display: none;
  }
  .mob_userProfile {
    display: flex;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }
  .sub_1 {
    width: 100vw;
  }
  .mob_userProfile .wrpr {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    scroll-snap-align: start;
  }

  .mob_userProfile .usernameWrpr {
    margin-top: 20px;
    height: 100vh;
    color: white;
    font-family: Poppins;
    width: 100vw;
    text-align: center;
  }
  .mob_userProfile .designation {
    font-weight: bold;
    font-family: Poppins;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .mob_userProfile .arrow {
    height: 20px;
    width: 20px;
    color: white;
  }
  .mob_userProfile .arrow1 {
    height: 20px;
    width: 20px;
    margin-left: -30px;
    color: white;
    margin-bottom: -30px;
  }
  .mob_userProfile .arrow2 {
    height: 20px;
    width: 20px;
    color: white;
    margin-left: 300px;
    margin-bottom: -30px;
  }
  .mob_userProfile .scroll {
    height: 10px;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    fill: white;
  }

  .mob_userProfile .publicUsername {
    font-size: 35px;
    font-weight: bold;
    padding: 0;
    text-align: center;
    margin: 0;
    font-family: Poppins;
  }
  .mob_userProfile .sub_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: linear-gradient(
      rgba(71, 132, 225, 0),
      rgba(71, 132, 225, 0.9)
    ); */
    /* height: 100%; */
    height: 100vh;
    align-items: center;
  }
  .mob_userProfile .socialContainer {
    /* margin-top: 20px; */
    width: 100vw;
    /* padding: 0px 0; */

    display: flex;
    flex-direction: row;
    padding-top: 60px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mob_userProfile .logo {
    height: 100px;
    padding-top: 10px;
    justify-self: flex-end;
  }
}

.scrollbar {
  height: 100vh;
}

.scrollbarr {
  scroll-snap-align: start;
}

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .sub_1 {
    background-attachment: initial;
  }
} */
