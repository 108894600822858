.contactMain {
  height: 80vh;
  padding-bottom: 25px;
}

.contactOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.us {
  padding-left: 180px;
  padding-top: 100px;
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
}
.contactSubtxt {
  width: 500px;
  font-size: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  line-height: 180%;
}
.contact {
  padding-left: 100px;
}
.contact_btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 0px 30px;
  color: white;
  border-radius: 40px;
  cursor: pointer;
}
.contact_btn_text {
  font-weight: 500;
  font-size: 25px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .contactMain {
    padding-bottom: 0;
  }
  .contactOuter {
    flex-direction: column;
  }

  .contactSubtxt {
    width: 300px;
  }
  .us {
    padding-left: 20px;
    padding-top: 20px;
  }
  .contact {
    padding: 5px;
    padding-top: 20px;
  }
}
