.dB-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.signout{
    align-self: flex-end;
}
.input-wrpr {
  margin: 20px;
  width: 80%;
  display: flex;
  justify-content: center;
}
.input-wrpr input {
  margin-right: 20px;
  padding: 5px;
}
.dB-table {
  width: 80%;
  display: flex;
  justify-content: center;
}
.dB-container table {
  border: 1px solid black;
  max-width: 80%;
  min-width: 60%;
}
.dB-container th {
  border-bottom: 2px solid black;
}
.dB-container td{
text-align: center;    
padding-top: 10px;
}
