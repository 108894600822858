.acc_settings {
    height: 90vh;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc_settings .sub {
  width: 30%;
  flex: 1;
}

.acc_settings .logout {
    cursor: pointer;
    margin-top: 250px;
}
@media only screen and (max-width: 600px) {
  .acc_settings .sub {
    width: 100%;
  }
}
