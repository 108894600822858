.contactBtn {
  width: 100px;
  height: 100px;
  margin: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contactBtn img {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.account_socio_btn {
  height: 130px;

  /* border: 0.5px solid green; */
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
  .contactBtn {
    margin: 10px 0;
    padding: 0;
    width: 70px;
    height: 70px;
  }
  .contactBtn img {
    margin: 10px 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .contactBtn p {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: white;
    font-family: Poppins;
  }
}
