.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 0 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  /* background-color: blue; */
}

.header_logo {
  padding-right: 20px;
  padding-left: 70px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  color: "#FFFFFF";
}
.header_logo > h4 {
  color: white;
}
.header_right {
  display: flex;
  flex-direction: row;
}

.header_right > p {
  padding-right: 30px;
  padding-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: white;
}
.header_right div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 52px;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 100px;
  box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
}
@media (min-width: 320px) and (max-width: 480px) {
  .header {
    justify-content: space-around;
  }
  .header_logo {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header_right p {
    padding-right: 20px;
    padding-left: 5px;
    font-size: 13px;
  }
  .header_right div {
    align-self: center;
    width: 100px;
    height: 26px;
    background-color: #ffffff;
    border-radius: 100px;
    box-shadow: 4px 4px 40px rgba(115, 115, 115, 0.25);
  }
  .header_right div p {
    font-size: 12px;
    padding-right: 0;
  }
}
