.whyMain {
  background-color: #edf3fc;
  padding-top: 50px;

}
.whySub {
  margin-left: 210px;
}
.why-h1 {
  margin-top: 70px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 34px;
  line-height: 66px;
  color: #434343;
}
.why-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.why-1 {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.why-txtwrpr {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 400px;
  height: 300px;
}
.img-why {
  background-color: white;
  border-radius: 24px;
  width: 35px;
  height: 38px;
  text-align: center;
  box-shadow: 4px 4px 10px rgba(118, 117, 117, 0.25);
}
.why-t1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;

}
.why-t2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.why-t3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 400px;
  padding: 0;
  margin: 0;
}
@media (min-width: 320px) and (max-width: 480px) {
  .whyMain{
    padding: 10px;
    padding-top: 10px;

  }
  .whySub {
    margin-left: 20px;
  }
  .why-1{
    width: 90%
  }
  .why-txtwrpr{
    width: 100%;
    height:auto;
  }
  .why-t3,.why-t1,.why-t2 {
    width: 100%;
    font-size: 12px;
  }
}
