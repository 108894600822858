.sticker1 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08));
  border-radius: 30px;
}

.sticker_image {
  width: 150px;
}

.img1 {
  width: 224px;
  height: 145px;
}

.img2 {
  width: 224px;
  height: 145px;
}
.img3 {
  width: 224px;
  height: 145px;
}

.card1 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.card2 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.card3 {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 30px;
}

.sticker_class {
  height: 150px;
  width: 150px;
  border-radius: 90px;
}

.customImage1 {
  border-radius: 8px;
  width: 224px;
  margin-left: 30px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sticker1 {
    width: 70vw;
    height: 260px;
  }
  .sticker_image {
    width: 100px;
  }
  .sticker_class {
    height: 100px;
    width: 100px;
  }
  .card1 {
    width: 70vw;
    height: 260px;
  }
  .card2 {
    width: 70vw;
    height: 260px;
  }
  .card3 {
    width: 70vw;
    height: 260px;
  }
  .img1 {
    width: 100px;
    height: 50px;
  }
  .img2 {
    width: 100px;
    height: 50px;
  }
  .combo_left {
    padding-left: 30px;
  }
  .customImage1 {
    width: 100px;
    height: 60px;
  }
  .img3 {
    width: 100px;
    height: 50px;
  }
}
