.create_username {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create_username .sub {
  width: max(40%,300px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.create_username .info {
  font-size: 15px;
  text-align: center;
  width: 80%;
}
.create_username .link_wrpr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create_username .dot {
  background-color: #c5fdaa;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create_username .dot p {
  margin: 0;
  padding: 0;
  color: white;
}
