.how_to {
  margin-top: 80px;
}
.item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  flex-direction: row-reverse;
}
.how_to > div:nth-of-type(odd) {
  background-color: #edf3fc;
}

.img_wrpr {
  width: 50%;
  /* padding-left: 25px; */
  /* background-color: yellow; */
}
.how_to .img {
  height: auto;
  object-fit: contain;
}
.how_to .text_wrpr {
  height: 100%;
  width: 35%;
  padding: 25px;
  /* background-color: yellow; */
}
.how_to .txt {
  margin-top: 20px;
  font-size: 18px;
  text-align: start;
}
@media (min-width: 320px) and (max-width: 480px) {
  .how_to {
    margin-top: 0px;
  }
  .item {
    height: 90vh;
  }
  .sec {
    flex-direction: column;
  }
 .how_to .img_wrpr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how_to .img {
    object-fit: contain;
  }
  .how_to .text_wrpr {
    width: 90%;
  }
}
