.activate {
  height: 100vh;
  display: flex;
}
.activate .text_wrpr {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.1em;
  padding: 20px;
  color: #434343;
}
.activate .img_wrpr {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activate .img {
  height: 60%;
  width: 60%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .activate {
    flex-wrap: wrap;
  }
  .activate .text_wrpr {
    width: 100%;
  }
  .activate .img_wrpr {
    width: 100%;
  }
  .activate .img {
    height: 80%;
    width: 80%;
  }
}
