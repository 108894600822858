.products_screen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.products {
  display: flex;
  padding-top: 40px;
  justify-content: space-around;
}
.modal {
  width: 400px;
  height: 400px;
}

.custom_products {
  display: flex;
  padding-top: 40px;

  padding-bottom: 20px;
  justify-content: space-around;
}

.sticker {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  /* filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08)); */
  border-radius: 30px;
}

.card {
  width: 543px;
  height: 284px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  /* filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.08)); */
  border-radius: 30px;
}

.rectangle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.img {
  width: 224px;
  height: 145px;
}

.details {
  font-family: Poppins;
  line-height: 18px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.details_sticker {
  font-family: Poppins;
  padding-top: 10px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.details1 {
  font-family: Poppins;
  line-height: 18px;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: #8e8e8e;
  width: 171px;
}

.cart {
  width: 130px;
  height: 40px;
  background-color: black;
  margin-top: 5px;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  margin-left: 30vw;
}
.cart div {
  background-color: white;
  width: 25px;
  height: 25px;
  margin-top: -60px;
  margin-left: 100px;
  border-radius: 70px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.row2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.combo_img {
  width: 220px;
  height: 120px;
}

.products_title {
  text-align: "center";
  font-family: Poppins;
  font-style: normal;
  font-size: 50px;
  line-height: 75px;
  margin-left: 40vw;
}
.combo_imagediv {
  /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08); */
  border-radius: 8px;
  margin-left: 100px;
}

.customImage {
  /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08); */
  border-radius: 8px;
  width: 224px;
  margin-left: 30px;
}

.combo_details {
  margin-left: 60px;
}
.inner_modal {
  margin-left: 60px;
}
.buttons {
  margin-top: 10px;
  cursor: pointer;
  background-color: #4784e1;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  padding: 5px;
}

.buttons2 {
  margin-top: 10px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  padding: 5px;
  border: 1px solid black;
  margin-left: 10px;
}

.button-text {
  margin: 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: white;
}

.button-text2 {
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: black;
}

@media (min-width: 320px) and (max-width: 480px) {
  .products {
    display: flex;
    padding-top: 10px;

    flex-direction: column;
  }
  .buttons {
    width: 60px;
  }

  .button-text {
    padding-left: 8px;
  }

  .buttons2 {
    width: 60px;
    margin-left: 2px;
  }

  .button-text2 {
    padding-left: 8px;
  }

  .inner_modal {
    margin-left: -10px;
  }
  .modal {
    width: 250px;
    height: 400px;
  }
  .custom_products {
    display: flex;
    padding-top: 10px;
    padding-left: 4px;
    flex-direction: column;
  }
  .card {
    width: 90vw;
    margin: 20px;
    height: 300px;
  }
  .img {
    width: 150px;
    height: 100px;
  }
  .cart {
    margin-left: 5vw;
  }
  .cart div {
    width: 20px;
    height: 20px;
    margin-left: 80px;
  }
  .cart p {
    font-size: 10px;
  }
  .sticker {
    width: 90vw;
    margin: 20px;
    height: 300px;
  }
  .sticker_1 {
    margin-left: 20px;
  }
  .details {
    width: 140px;
  }
  .details1 {
    width: 140px;
  }
  .row2 {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
  }
  .combo_img {
    width: 130px;
    height: 100px;
  }
  .combo_details {
    padding-left: -40px;
  }
  .combo_imagediv {
    margin-left: 110px;
  }
  .customImage {
    width: 150px;
    height: 100px;
  }
  .products_title {
    margin-left: 5vw;
  }
}
