.account * {
  padding: 0;
  margin: 0;
}
input:focus {
  /* border: none; */
  outline: none;
}
.account {
}
.account .sub {
  margin: auto;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account .subTitle {
  margin: 20px;
  font-size: 20px;
  color: gray;
  justify-self: flex-start;
  align-self: flex-start;
}
.account .socialContainer {
  margin: 0 auto 20px auto;
  display: flex;
  flex-wrap: wrap;
}


