.sect_1 {
  background-color: #4287f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.faq .wrpr {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: white;
}
.faq .wrpr h1 {
  margin: 0 10px;
  font-weight: 600;
  font-size: 58px;
  text-align: center;
}
.faq .wrpr p {
  font-size: 22px;
}
.faq .sect_2 {
  padding: 120px 30px 18px 110px;
  display: flex;
  flex-direction: column;
  background-color: rgba(71, 132, 225, 0.1);
}
.faq .sect_2 {
  font-size: 22px;
  line-height: 33px;
}
.faq .sect_2 h1 {
  font-size: 44px;
  align-self: center;
  color: #434343;
}

@media only screen and (max-width: 600px) {
  .faq .wrpr h1 {
    margin-top: 40px;
    font-size: 40px;
  }
  .faq .wrpr p {
    font-size: 18px;
  }
  .faq .sect_2 {
    padding: 80px 30px 18px 30px;
    font-size: 18px;
  }
  .faq .sect_2 .info {
    text-align: center;
    margin-top: 40px;
  }
  
}
