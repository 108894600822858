.delete_account {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
}
.delete_account .sub {
  padding: 20px;
  width: 30%;
}
.delete_account .input {
  width: 90%;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .delete_account .sub {
    width: 90%;
    /* background-color: red; */
  }
}
