.auth_header {
  width: 100%;
  height: 150px;
  /* position: absolute;
  top: 0%;
  left: 0%; */
  transform: translate(0%, 0%);
  border-radius: 0 0 100% 100%;
  background-color: #558ee30d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_header p {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.login {
  height: 90vh;
  display: flex;
  flex-direction: column;

  align-items: center; /* background-color: rgb(170, 170, 228); */
}

.login .sub {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 40%;
}
.auth_input_wrpr {
  margin-top: 50px;
  width: 100%;
  border-bottom: 1px solid #383838a6;
  padding-bottom: 5px;
}
.auth_input {
  border: none;
  width: 100%;
}
.forgot {
  margin: 10px 0;
  align-self: start;
  color: #383838;
  font-size: 12px;
}

.login .cbx_wrpr {
  display: flex;
  justify-content: start;
  font-size: 12;
}

.otp_page .sub {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.otp_page .info {
  font-size: 15px;
  text-align: center;
  width: 80%;
}
.resend {
  text-decoration-line: underline;
  color: #383838;
  font-size: 13px;
  align-self: flex-start;
}
@media (min-width: 320px) and (max-width: 480px) {
  .auth_input_wrpr {
    width: 100%;
  }
  .login .sub {
    width: 95%;
  }
}
